.profileCard {
  width: 100vw;
  height: calc(100% - 246px);
  min-height: calc(100vh - 246px);
  margin-top: 32px;
  padding-bottom: 16px;

  background: #f8f8f8;
  box-shadow: 0px 0px 10px 4px #ededed;
  border-radius: 16px;
}

.SubmitRequestCard {
  width: 100vw;
  height: calc(100% - 246px);
  min-height: calc(100vh - 246px);
  margin-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  background: #f8f8f8;
  box-shadow: 0px 0px 10px 4px #ededed;
  border-radius: 16px;
}

.transferToken {
  width: 100vw;
  height: calc(100% - 246px);
  min-height: calc(100vh - 246px);
  margin-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  background: #f8f8f8;
  box-shadow: 0px 0px 10px 4px #ededed;
  border-radius: 16px;
}

.accountBalance {
  width: 90%;
  background: #7a5a95;
  border-radius: 16px;

  margin-left: auto;
  margin-right: auto;
}

.accountBalanceButton {
  width: 100%;
  height: 48px;
  background: #9a82ae;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 16px 16px;

  font-weight: 700;
  font-size: 16px;
  line-height: 16px;

  color: #ffffff;
}

.accountBalanceTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 16px;

  padding-top: 32px;

  color: #ffffff;
}

.accountBalanceContent {
  font-weight: 400;
  font-size: 32px;
  line-height: 16px;

  padding-top: 32px;
  padding-bottom: 32px;

  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .desktopResponsive {
    background-color: #ffffff;
  }

  .desktopResponsive h1 {
    text-align: center;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }

  .desktopResponsive a {
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
  }

  .desktopResponsive a:nth-child(2) {
    margin-top: 268px;
  }

  .desktopResponsive form {
    margin-left: 15%;
    margin-right: 15%;
  }

  .resendCodeButton {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media screen and (max-width: 992px) {
  .desktopResponsive h1 {
    width: 296px;
  }

  .desktopResponsive button {
    width: 100%;
  }

  .desktopResponsive form {
    margin-left: 16px;
    margin-right: 16px;
  }

  .desktopResponsive a:nth-child(1) {
    margin-left: 16px;
  }

  .desktopResponsive a:nth-child(2) {
    margin-right: 16px;
  }

  .resendCodeButton {
    margin-left: 16px;
    margin-right: 16px;
  }
}
